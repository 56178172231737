import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { PaymentMethodBugs as bugs } from 'components/Shared/Icons/Icons';

export interface PaymentMethodBugsProps {
  enabledPaymentMethods?: Array<string>;
  enabledCCTypes: Array<string>;
  cdn: string;
  ccBugsOnly?: boolean;
}

const PMBugs: React.FC<PaymentMethodBugsProps> = ({
  enabledPaymentMethods = [],
  enabledCCTypes = [],
  cdn,
  ccBugsOnly = true,
}) => {
  if (!Array.isArray(enabledCCTypes)) {
    return null;
  }
  const intl = useIntl();
  const ccBugs: Array<Object> = [];
  const paymentMethodsBugs = [];

  if (enabledCCTypes.includes('visa')) {
    const visaAltText = intl.formatMessage({
      id: 'VISA_LOGO_ALT',
      defaultMessage: 'Visa logo',
    });
    ccBugs.push(
      <li key="visa">
        <bugs.Visa cdn={cdn} altText={visaAltText} />
      </li>
    );
  }

  if (enabledCCTypes.includes('masterCard')) {
    const mastercardAltText = intl.formatMessage({
      id: 'MASTERCARD_LOGO_ALT',
      defaultMessage: 'Mastercard logo',
    });
    ccBugs.push(
      <li key="masterCard">
        <bugs.MasterCard cdn={cdn} altText={mastercardAltText} />
      </li>
    );
  }

  if (enabledCCTypes.includes('discover')) {
    const discoverAltText = intl.formatMessage({
      id: 'DISCOVER_LOGO_ALT',
      defaultMessage: 'Discover logo',
    });
    ccBugs.push(
      <li key="discovery">
        <bugs.Discover cdn={cdn} altText={discoverAltText} />
      </li>
    );
  }

  if (enabledCCTypes.includes('amex')) {
    const amexAltText = intl.formatMessage({
      id: 'AMEX_LOGO_ALT',
      defaultMessage: 'Amex logo',
    });
    ccBugs.push(
      <li key="amex">
        <bugs.Amex cdn={cdn} altText={amexAltText} />
      </li>
    );
  }

  if (enabledCCTypes.includes('jcb')) {
    const amexAltText = intl.formatMessage({
      id: 'JCB_LOGO_ALT',
      defaultMessage: 'JCB logo',
    });
    ccBugs.push(
      <li key="jcb">
        <bugs.JCB cdn={cdn} altText={amexAltText} />
      </li>
    );
  }
  const isPayPal =
    enabledPaymentMethods.includes('paypal') || enabledPaymentMethods.includes('paypal_ppaam');
  if (isPayPal) {
    const paypalAltText = intl.formatMessage({
      id: 'PAYPAL_LOGO_ALT',
      defaultMessage: 'Paypal logo',
    });
    paymentMethodsBugs.push(
      <li key="pp">
        <bugs.PayPal cdn={cdn} altText={paypalAltText} />
      </li>
    );
  }

  if (enabledPaymentMethods.includes('ap')) {
    const appleAltText = intl.formatMessage({
      id: 'APPLE_LOGO_ALT',
      defaultMessage: 'Apple logo',
    });
    paymentMethodsBugs.push(
      <li key="ap">
        <bugs.ApplePay cdn={cdn} altText={appleAltText} />
      </li>
    );
  }

  if (enabledPaymentMethods.includes('bank')) {
    const discoverAltText = intl.formatMessage({
      id: 'PAYFLOW_PAYMENT_METHOD_BANK_TEST',
      defaultMessage: 'Bank',
    });
    paymentMethodsBugs.push(
      <li key="bank">
        <bugs.Bank cdn={cdn} altText={discoverAltText} />
      </li>
    );
  }

  return (
    <Fragment>
      {enabledPaymentMethods && enabledPaymentMethods.length > 0 && (
        <div className="bugs">
          <ul>{ccBugsOnly ? ccBugs : [...ccBugs, ...paymentMethodsBugs]}</ul>
        </div>
      )}
      <style jsx>{`
        .bugs {
          ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            :global(li) {
              display: inline-block;
              height: 22px;

              &:not(first-child) {
                margin-left: 5px;
              }
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default PMBugs;
