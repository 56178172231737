// @ts-nocheck

import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import { displayGratuityFeature } from 'businessLogic/Payment/Gratuity';
import { isMAIPasPDF } from 'businessLogic/Payment/helpers';
import { isWalletTypeOfBank, isWalletTypeOfCreditCard } from 'businessLogic/Wallet/helpers';
import ScheduleAndAutoPay from 'components/Core/Autopay-schedule/ScheduleAndAutoPay/ScheduleAndAutoPay';
import BankTransfer from 'components/Core/Payment/Bank/Transfer/BankTransfer';
import BankUpdate from 'components/Core/Payment/Bank/Update/BankUpdate';
import CardPayment from 'components/Core/Payment/Card/Payment/CardPayment';
import CardUpdate from 'components/Core/Payment/Card/Update/CardUpdate';
import Gratuity from 'components/Core/Payment/Gratuity/Gratuity';
import BankForm from 'components/Core/Payment/PaymentForm/Bank/BankForm';
import CardForm from 'components/Core/Payment/PaymentForm/Card/CardForm';
import PayPalCardForm from 'components/Core/Payment/PaymentForm/PayPalCardForm/PayPalCardForm';
import SSRSuspense from 'components/Core/Payment/PaymentForm/SSRSuspense/SSRSuspense';
import DesktopPaymentMethodTabs from 'components/Core/Payment/PaymentMethod/DesktopPaymentMethodTabs/DesktopPaymentMethodTabs';
import MobilePaymentMethodWrapper from 'components/Core/Payment/PaymentMethod/MobilePaymentMethodTabs/MobilePaymentMethodTabs';
import PaymentMethodBugs from 'components/Core/Payment/PaymentMethod/PaymentMethodBugs/PaymentMethodBugs';
import SavePaymentMethodCTA from 'components/Core/Payment/PaymentMethod/SavePaymentMethod/SavePaymentMethodCTA/SavePaymentMethodCTA';
import SavedPaymentMethods from 'components/Core/Payment/PaymentMethod/SavePaymentMethod/SavedPaymentMethods';
import DeleteWalletModal from 'components/Core/Payment/Wallet/DeleteWalletModal/DeleteWalletModal';
import TrustSection from 'components/Core/TrustSection/TrustSection';
import ContactDetails from 'components/PaymentReq/OneToMany/ContactDetails/ContactDetails';
import FormSectionHeader from 'components/PaymentReq/OneToMany/FormSectionHeader/FormSectionHeader';
import Button from 'components/Shared/Button/Button';
import CKMonthlyExperiment from 'components/Shared/CKMonthlyExperiment/CKMonthlyExperiment';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import Separator from 'components/Shared/Separator/Separator';
import SpinnerCentered from 'components/Shared/Spinner/SpinnerCentered/SpinnerCentered';
import FormDraftProvider from 'contexts/FormDraftProvider';
import SegmentIO from 'reporting/SegmentIO';
import { getEnabledPaymentMethod, getPaymentMethodsOrder } from 'shared/clientUtils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { modalActions } from 'store/modal/slice';
import { isSDPaymentMethodSelected } from 'store/payment/selectors';
import {
  walletTokenizationPaymentCreation,
  paymentMethodTypeChange,
  paymentActions,
} from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { toastActions } from 'store/toast/slice';
import { walletActions, changeSelectedWallet, deleteWallet } from 'store/wallet/slice';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { Auth } from 'types/Auth';
import { Config } from 'types/Config';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { Insight } from 'types/Insight';
import { Payment as PaymentTypes } from 'types/Payment';
import { Sale } from 'types/Sale';
import { Wallet } from 'types/Wallet';
import { PaymentMethod, TXN_MAP } from 'types/constants';

const LazyNanopay = React.lazy(() => import('components/Core/Nanopay/Nanopay'));
const LazyNanopayEmpty = React.lazy(() => import('components/Core/Nanopay/Empty/NanopayEmpty'));

// rest of the cards have same name in wallet widget
const WALLET_WIDGET_NAME_ADAPTER = {
  amex: 'american-express',
  diners: 'diners-club',
  masterCard: 'mastercard',
};

type PaymentProps = {
  companyLocale?: string;
  auth: Pick<Auth, 'isUserSignedIn' | 'countryCode'>;
  featureFlags: FeatureFlags;
  config: Config;
  payment: Pick<
    PaymentTypes,
    'paymentMethodType' | 'isPayPalCommerceInvoice' | 'paymentStatus' | 'balanceAmount' | 'country',
    'paymentMethodSubType'
  >;
  wallet: Pick<
    Wallet,
    | 'currentlyUpdatingWalletId'
    | 'pendingDeleteWallet'
    | 'updateWalletStatus'
    | 'deleteWalletStatus'
    | 'userWallets'
    | 'enabledPaymentMethods'
    | 'enabledCCTypes'
    | 'selectedWalletId'
    | 'bankCreatePaymentTrigger'
    | 'cardCreatePaymentTrigger'
    | 'isPPAAMInstallmentsEnabled'
  >;
  paymentMethodsOrder: any[];
  isSubscription: boolean;
  setIsNewWalletUI: Function;
  showToast: Function;
  intl: IntlShape;
  onUpdatePaymentMethodClicked: (...args: any[]) => any;
  showModal: Function;
  onDeletePaymentMethodClicked: Function;
  deleteWallet: Function;
  cancelDeleteWallet: Function;
  changeSelectedWallet: Function;
  paymentMethodTypeChange: (paymentMethod: PaymentMethod | string) => any;
  isInvoiceGratuityEnabled: boolean;
  companyName: string;
  ixp: IXP;
  createPayment: (...args: any[]) => any;
  bindFormSubmission: (...args: any[]) => any;
  sale: Pick<Sale, 'amount'>;
  updateCountry: (...args: any[]) => any;
  stopPayment: (...args: any[]) => any;
  // NOTE: The below function is a candidate to be removed. Possible dead code.
  stopPaymentProgress?: (...args: any[]) => any;
  onCvvInputValueChange: (...args: any[]) => any;
  hideModal: Function;
  insight: Insight;
};

type PaymentState = {
  showPaymentTabs: boolean;
  shouldUseNewWalletFlow: boolean;
};

class Payment extends Component<PaymentProps, PaymentState> {
  constructor(props: PaymentProps) {
    super(props);
    this.state = {
      showPaymentTabs: false,
    };
  }
  componentDidMount() {
    let showPaymentTabs;
    const { auth, isSubscription, setIsNewWalletUI } = this.props;
    showPaymentTabs = !auth.isUserSignedIn;

    if (isSubscription) {
      // updating a flag in the wallet store to be used in logger
      setIsNewWalletUI(true);
    }

    this.setState({ showPaymentTabs, shouldUseNewWalletFlow: isSubscription });
  }
  componentDidUpdate(prevProps: PaymentProps) {
    // *this should be revisit*
    // check if this update is after a sucsseful update
    if (
      !this.props.wallet.currentlyUpdatingWalletId &&
      this.props.wallet.updateWalletStatus === TXN_MAP.STATUS.SUCCESS &&
      prevProps.wallet.updateWalletStatus !== TXN_MAP.STATUS.SUCCESS
    ) {
      const { updatedWallet = {} } = this.props.wallet;
      if (isWalletTypeOfBank(updatedWallet.walletType)) {
        this.props.showToast({
          text: this.props.intl.formatMessage(
            {
              id: 'PAYFLOW_ACOUNT_UPDATED',
            },
            {
              0: `${updatedWallet.accountType}${updatedWallet.accountNumber}`,
            }
          ),
        });
      } else if (isWalletTypeOfCreditCard(updatedWallet.walletType)) {
        const { cardType, cardNumber } = updatedWallet;
        const cardRepresentation = `${cardType.toUpperCase()}...${cardNumber.slice(-4)}`;
        this.props.showToast({
          text: this.props.intl.formatMessage(
            {
              id: 'PAYFLOW_ACOUNT_UPDATED',
            },
            {
              0: cardRepresentation,
            }
          ),
        });
      }
    }
    if (
      this.props.wallet.deleteWalletStatus === TXN_MAP.STATUS.SUCCESS &&
      prevProps.wallet.deleteWalletStatus !== TXN_MAP.STATUS.SUCCESS
    ) {
      const { pendingDeleteWallet = {} } = this.props.wallet;
      if (isWalletTypeOfBank(pendingDeleteWallet.walletType)) {
        this.props.showToast({
          text: this.props.intl.formatMessage(
            {
              id: 'PAYFLOW_ACOUNT_DELETED',
            },
            {
              0: `${pendingDeleteWallet.accountType}`,
              1: `${pendingDeleteWallet.accountNumber}`,
            }
          ),
        });
      } else if (isWalletTypeOfCreditCard(pendingDeleteWallet.walletType)) {
        const creditOrDebit = 'credit'; // Can we even know that?
        const { cardType, cardNumber } = pendingDeleteWallet;
        const cardRepresentation = `${cardType.toUpperCase()}...${cardNumber.slice(-4)}`;
        this.props.showToast({
          text: this.props.intl.formatMessage(
            {
              id: 'PAYFLOW_CARD_DELETED',
            },
            {
              0: `${creditOrDebit}`,
              1: `${cardRepresentation}`,
            }
          ),
        });
      }
    }
  }
  onUpdatePaymentMethodClicked = (id, walletType) => {
    this.props.onUpdatePaymentMethodClicked({ id });

    SegmentIO.transactionEngaged({
      activity_type: 'wallet',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'edit_wallet',
      ui_access_point: 'menu_all',
    });

    const componentToShow = isWalletTypeOfBank(walletType) ? BankUpdate : CardUpdate;
    this.props.showModal({
      component: componentToShow,
      componentProps: {
        closeOnEsc: true,
      },
    });
  };
  onDeletePaymentMethodClicked = (id, bankOrCard) => {
    this.props.onDeletePaymentMethodClicked({ id });

    SegmentIO.transactionEngaged({
      activity_type: 'wallet',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'delete_wallet',
      ui_access_point: 'menu_all',
    });

    this.props.showModal({
      component: DeleteWalletModal,
      componentProps: {
        closeOnEsc: true,
        onOk: async () => {
          await this.props.deleteWallet({
            id,
            bankOrCard,
          });
          const typeToFilterBy = bankOrCard === 'bank' ? 'bank' : 'card';
          const wallets = this.props.wallet.userWallets?.filter(
            (wallet) => wallet.walletType === typeToFilterBy
          );
          const addWalletId = bankOrCard === 'bank' ? 'AddBank' : 'AddCard';
          const newWalletId = wallets[0] ? wallets[0].id : addWalletId;
          this.changePaymentMethod({
            id: newWalletId,
          });
        },
        onCancel: () => {
          this.props.cancelDeleteWallet();
        },
      },
    });
  };
  switchToPayForm = () => {
    const { paymentMethodType } = this.props.payment;
    this.props.changeSelectedWallet(paymentMethodType === 'bank' ? 'AddBank' : 'AddCard');
    this.setState({ showPaymentTabs: true });
  };
  switchToSavedPaymentMethods = () => {
    const { userWallets } = this.props.wallet;
    const defaultWallet = userWallets?.find((wallet) => wallet.default);
    const walletId = defaultWallet ? defaultWallet.id : userWallets[0].id;
    this.props.changeSelectedWallet(walletId);
    this.setState({ showPaymentTabs: false });
  };
  // Can be removed after we officially use the new flow
  changePaymentMethod = ({ id }) => {
    this.props.changeSelectedWallet(id);
    this.props.paymentMethodTypeChange({ paymentMethodType: this.props.payment.paymentMethodType });
  };
  render() {
    const {
      config,
      insight: { payorSegmentation, domainId },
      wallet: {
        enabledPaymentMethods,
        enabledCCTypes,
        selectedWalletId,
        bankCreatePaymentTrigger,
        cardCreatePaymentTrigger,
        userWallets,
      },
      payment: { isPayPalCommerceInvoice, paymentMethodType, paymentStatus, balanceAmount },
      paymentMethodsOrder,
      auth: { isUserSignedIn, countryCode },
      ixp,
      isInvoiceGratuityEnabled = false,
      featureFlags,
      createPayment,
      companyLocale,
      bindFormSubmission,
      paymentMethodTypeChange,
      isSubscription,
      sale,
    } = this.props;

    const { shouldUseNewWalletFlow } = this.state;
    const isPaypalORVenmo = ['paypal_ppaam', 'venmo'].includes(paymentMethodType);
    const isCKMonthlyExperimentSelected = isSDPaymentMethodSelected({
      payment: this.props.payment,
    });

    const {
      endpoints: { cdn },
    } = config;
    // If there is only one payment option available, we need to show the user a message.
    const maybeShowUserPaymentOptionMessage = () => {
      // Out of the enabledPaymentMethods, filter only the payment options that are on the PaymentMethodTabs => bank, cc, dc, ap or paypal.
      // enabledPaymenntOptions: ['bank', 'cc', 'dc', 'ap', 'pp'];
      let enabledPaymentOptions = getEnabledPaymentMethod(enabledPaymentMethods);
      if (!Array.isArray(enabledPaymentOptions) || isCKMonthlyExperimentSelected) {
        return null;
      }
      // If there is more than one payment options, then no need to show message.
      if (enabledPaymentOptions.length > 1) {
        return null;
      }
      // There is only one payment option. Currently, only show this message for bank or credit card, so if it isn't one of those, no need to show the message.
      if (enabledPaymentOptions[0] === 'bank') {
        return (
          <label className="pay-with-title">
            <FormattedMessage
              id="PAYNOW_PAYNOW_PAY_WITH_BANK_TRANSFER"
              defaultMessage="Pay with bank transfer"
            />
          </label>
        );
      } else if (enabledPaymentOptions[0] === 'cc') {
        return (
          <label className="pay-with-title">
            <FormattedMessage
              id="PAYNOW_PAYNOW_PAY_WITH_CREDIT_CARD"
              defaultMessage="Pay with credit card"
            />
          </label>
        );
      } else if (enabledPaymentOptions[0] === 'nanopay') {
        return (
          <label className="pay-with-title">
            <FormattedMessage
              id="PAYNOW_PAYNOW_PAY_WITH_BANK_PAYMENT"
              defaultMessage="Pay with bank payment"
            />
          </label>
        );
      }
      return null;
    };
    const getAllowedCardTypes = () => {
      return enabledCCTypes.map(
        (allowedCardType) => WALLET_WIDGET_NAME_ADAPTER[allowedCardType] || allowedCardType
      );
    };
    const getSelectedPaymentMethodContent = () => {
      if (
        isPayPalCommerceInvoice &&
        (paymentMethodType === 'dc' || paymentMethodType === 'cc' || paymentMethodType === 'dc,cc')
      ) {
        return (
          <PayPalCardForm
            config={this.props.config}
            wallet={this.props.wallet}
            auth={this.props.auth}
            payment={this.props.payment}
            paymentAmountChange={this.props.paymentAmountChange}
            key={this.props.payment}
          />
        );
      }
      if (paymentMethodType === 'nanopay') {
        const {
          config: { nanopay: { minimumAmountForBankPayment = 1 } = {} } = {},
          insight: { isPartiallyPaid } = {},
        } = this.props;
        if (this.props.sale.amount >= minimumAmountForBankPayment && !isPartiallyPaid) {
          return (
            <SSRSuspense fallback={<SpinnerCentered />}>
              <LazyNanopay config={this.props.config} />
            </SSRSuspense>
          );
        } else {
          return (
            <SSRSuspense fallback={<SpinnerCentered />}>
              <LazyNanopayEmpty companyName={this.props.companyName} cdn={cdn} />
            </SSRSuspense>
          );
        }
      }
      if (isPaypalORVenmo) {
        return <Fragment />;
      }
      if (['bank', 'dc', 'cc', 'dc,cc', 'eft'].includes(paymentMethodType)) {
        return getBankOrCCPaymentMethodContent();
      }
      if (paymentMethodType === 'credit_karma') {
        return (
          <CKMonthlyExperiment
            amount={balanceAmount}
            payEnabled={true}
            domainId={domainId}
            isBusiness={payorSegmentation?.isBusiness}
          />
        );
      }
      return null;
    };

    const getBankOrCCPaymentMethodContent = () => {
      if (shouldUseNewWalletFlow) {
        const formProps = {
          paymentStatus,
          createPayment,
          stopPayment: paymentActions.cancelPayment,
          bindFormSubmission,
        };
        if (paymentMethodType === 'bank') {
          const _bankFormProps = {
            ...formProps,
            boundCreateBankFn: bankCreatePaymentTrigger,
          };
          return (
            <FormDraftProvider.Consumer>
              {({ updateDraft, bank }) => (
                <BankForm
                  bankDraft={bank}
                  paymentMethodType={paymentMethodType}
                  updateFormDraft={(draft) => updateDraft(paymentMethodType, draft)}
                  {..._bankFormProps}
                />
              )}
            </FormDraftProvider.Consumer>
          );
        }
        if (['dc', 'cc', 'dc,cc'].includes(paymentMethodType)) {
          const _cardFormProps = {
            ...formProps,
            paymentMethodType,
            boundCreateCardFn: cardCreatePaymentTrigger,
            bindFormSubmission,
            endpoints: config.endpoints,
            companyLocale,
            enabledPaymentMethods,
            featureFlags,
            countryCode,
            country: this.props.payment.country,
            updateCountry: this.props.updateCountry,
          };
          return (
            <FormDraftProvider.Consumer>
              {(ctx) => (
                <CardForm
                  cardDraft={ctx[paymentMethodType]}
                  updateFormDraft={(draft) => ctx.updateDraft(paymentMethodType, draft)}
                  allowedCardTypes={getAllowedCardTypes()}
                  {..._cardFormProps}
                />
              )}
            </FormDraftProvider.Consumer>
          );
        }
      } else {
        if (paymentMethodType === 'bank' || paymentMethodType === 'eft') {
          return (
            <BankTransfer
              bindFormSubmission={this.props.bindFormSubmission}
              isUserSignedIn={isUserSignedIn}
              config={config}
              createPayment={this.props.createPayment}
              stopPayment={this.props.stopPayment}
              changeSelectedBank={this.changePaymentMethod}
              stopPaymentProgress={this.props.stopPaymentProgress}
              onDeletePaymentMethodClicked={this.onDeletePaymentMethodClicked}
              onUpdatePaymentMethodClicked={this.onUpdatePaymentMethodClicked}
              wallet={this.props.wallet}
              payment={this.props.payment}
              showModal={this.props.showModal}
              hideModal={this.props.hideModal}
            />
          );
        }
        if (['dc', 'cc', 'dc,cc'].includes(paymentMethodType)) {
          return (
            <CardPayment
              bindFormSubmission={this.props.bindFormSubmission}
              auth={this.props.auth}
              config={this.props.config}
              createPayment={this.props.createPayment}
              stopPayment={this.props.stopPayment}
              changeSelectedCard={this.changePaymentMethod}
              stopPaymentProgress={this.props.stopPaymentProgress}
              onDeletePaymentMethodClicked={this.onDeletePaymentMethodClicked}
              onUpdatePaymentMethodClicked={this.onUpdatePaymentMethodClicked}
              wallet={this.props.wallet}
              featureFlags={this.props.featureFlags}
              payment={this.props.payment}
              companyLocale={this.props.companyLocale}
              onCvvInputValueChange={this.props.onCvvInputValueChange}
              ixp={ixp}
              updateCountry={this.props.updateCountry}
            />
          );
        }
      }
    };
    const isAddingBank =
      (paymentMethodType === 'bank' || paymentMethodType === 'eft') &&
      (selectedWalletId === 'AddBank' || !selectedWalletId);
    const isAddingCard =
      ['cc', 'dc', 'dc,cc'].includes(paymentMethodType) &&
      (selectedWalletId === 'AddCard' || !selectedWalletId);
    const isAddingPaymentMethod = isAddingBank || isAddingCard;
    const shouldShowSavePaymentMethodCta =
      isAddingPaymentMethod && !isSubscription && !isPaypalORVenmo && !isPayPalCommerceInvoice;
    const doesUserHaveWallets = userWallets && userWallets.length > 0;
    const shouldShowPaymentTabs =
      this.state.showPaymentTabs ||
      !doesUserHaveWallets ||
      isPayPalCommerceInvoice ||
      !shouldUseNewWalletFlow;
    const shouldShowBackToSavedMethodsBtn =
      isUserSignedIn && doesUserHaveWallets && !isPayPalCommerceInvoice && shouldUseNewWalletFlow;
    const isMaipASPDFEnabled = isMAIPasPDF({ sale, featureFlags });

    return (
      <Fragment>
        <FormDraftProvider.Provider>
          {isMaipASPDFEnabled && (
            <Fragment>
              <ContactDetails shouldShowContactName={false} />
              <Separator height={12} />
              <FormSectionHeader content="PAYMENT DETAILS" />
              <Separator height={12} />
            </Fragment>
          )}
          {shouldShowPaymentTabs ? (
            <Fragment>
              <div className="new-payment-method-header">
                {shouldUseNewWalletFlow && (
                  <p>
                    <FormattedMessage
                      id="PAYFORM_SELECT_PAYMENT_METHOD"
                      defaultMessage="Select payment method"
                    />
                  </p>
                )}
                {shouldShowBackToSavedMethodsBtn && (
                  <div className="back-btn">
                    <Button
                      buttonType="tertiary"
                      size="standard"
                      padding="0"
                      onClick={this.switchToSavedPaymentMethods}
                    >
                      Go back to saved payment methods
                    </Button>
                  </div>
                )}
              </div>
              <div className="payment-tabs-desktop">
                <DesktopPaymentMethodTabs
                  ixp={ixp}
                  paymentMethodTypeChange={paymentMethodTypeChange}
                  changeSelectedWallet={this.props.changeSelectedWallet}
                  payment={this.props.payment}
                  allowedPaymentMethods={paymentMethodsOrder}
                  featureFlags={this.props.featureFlags}
                  shouldUseNewWalletFlow={shouldUseNewWalletFlow}
                  wallet={this.props.wallet}
                />
              </div>
              <div className="payment-tabs-mobile" data-testid="mobile-payment-selector">
                <MobilePaymentMethodWrapper
                  paymentMethodTypeChange={paymentMethodTypeChange}
                  changeSelectedWallet={this.props.changeSelectedWallet}
                  isUserSignedIn={isUserSignedIn}
                  showModal={this.props.showModal}
                  featureFlags={this.props.featureFlags}
                  hideModal={this.props.hideModal}
                  payment={this.props.payment}
                  insight={this.props.insight}
                  allowedPaymentMethods={paymentMethodsOrder}
                  shouldUseNewWalletFlow={shouldUseNewWalletFlow}
                  enabledPaymentMethods={enabledPaymentMethods}
                  enabledCCTypes={enabledCCTypes}
                  cdn={cdn}
                  ixp={ixp}
                  wallet={this.props.wallet}
                />
              </div>

              {maybeShowUserPaymentOptionMessage()}
              {getSelectedPaymentMethodContent()}
              {(paymentMethodType === 'paypal_ppaam' || paymentMethodType === 'venmo') &&
                featureFlags['cp-increase-max-amount-limit'] &&
                !this.props.payment.isAmountValid && (
                  <MessageCard
                    text={
                      paymentMethodType === 'venmo'
                        ? 'VENMO_WARN_MESSAGE_AMOUNT_EXCEED'
                        : 'PAYPAL_WARN_MESSAGE_AMOUNT_EXCEED'
                    }
                    status="orangeWarn"
                    messageFontSize="14px"
                    errorMessageIconPosition="start"
                  />
                )}
              <div className="payment-method-footer">
                {shouldShowSavePaymentMethodCta ? (
                  <div className="save-payment-method">
                    <SavePaymentMethodCTA />
                  </div>
                ) : (
                  <div />
                )}
                {isAddingCard && (
                  <PaymentMethodBugs
                    enabledPaymentMethods={enabledPaymentMethods}
                    enabledCCTypes={enabledCCTypes}
                    cdn={cdn}
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <SavedPaymentMethods
              onUpdatePaymentMethodClicked={this.onUpdatePaymentMethodClicked}
              onDeletePaymentMethodClicked={this.onDeletePaymentMethodClicked}
              onAddPaymentMethodClicked={this.switchToPayForm}
              bindFormSubmission={bindFormSubmission}
            />
          )}
          {!isMaipASPDFEnabled && <ScheduleAndAutoPay />}
          {isInvoiceGratuityEnabled && !isCKMonthlyExperimentSelected && <Gratuity />}
          <div className="truste-w">
            <TrustSection cdn={config.endpoints.cdn} />
          </div>
          <style jsx>{`
            :global(.pay-with-title) {
              font-family: AvenirNextforINTUIT-Demi;
              margin-bottom: 13px;
              display: block;
              font-size: ${fontSize.sm};
              cursor: text;
            }

            .save-payment-method > div {
              display: inline-flex;
            }

            .new-payment-method-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              font-family: AvenirNextforINTUIT-Demi;
              color: ${colors.gray02};
              font-weight: 600;

              @media screen and (max-width: ${breakpoints.md}) {
                flex-direction: column;
                align-items: flex-start;
              }

              .back-btn {
                @media screen and (max-width: ${breakpoints.md}) {
                  order: -1;
                }
              }
            }

            .payment-tabs-desktop {
              @media screen and (max-width: ${breakpoints.md}) {
                display: none;
              }
            }

            .payment-tabs-mobile {
              display: none;
              @media screen and (max-width: ${breakpoints.md}) {
                display: block;
              }
            }

            .payment-method-footer {
              padding: 8px 0 24px;
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: ${breakpoints.md}) {
                flex-direction: column;
                align-items: center;
                gap: 8px;
              }
            }

            .truste-w {
              @media screen and (max-width: ${breakpoints.md}) {
                display: block;
              }
              display: none;

              margin-bottom: 15px;
            }
            .top-pm-bugs {
              @media screen and (min-width: ${breakpoints.lg}) {
                display: none;
              }
            }
          `}</style>
        </FormDraftProvider.Provider>
      </Fragment>
    );
  }
}
function mapStateToProps(store) {
  const { companyInfo, sale, config, payment, insight, wallet, auth, ixp, featureFlags } = store;

  return {
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    auth,
    config,
    payment,
    sale,
    wallet,
    featureFlags,
    insight,
    ixp,
    isInvoiceGratuityEnabled: displayGratuityFeature(store),
    paymentMethodsOrder: getPaymentMethodsOrder({
      enabledPaymentMethods: wallet.enabledPaymentMethods,
    }),
    isSubscription: saleSelectors.isSubscription(sale),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    paymentMethodTypeChange,
    changeSelectedWallet,
    bindFormSubmission: walletActions.bindFormSubmission,
    createPayment: walletTokenizationPaymentCreation,
    stopPayment: paymentActions.cancelPayment,
    showModal: modalActions.show,
    hideModal: modalActions.hide,
    showToast: toastActions.show,
    onDeletePaymentMethodClicked: walletActions.onDeletePaymentMethodClicked,
    onUpdatePaymentMethodClicked: walletActions.onUpdatePaymentMethodClicked,
    deleteWallet,
    cancelDeleteWallet: walletActions.deleteWalletCancel,
    onCvvInputValueChange: walletActions.onCvvInputValueChange,
    setIsNewWalletUI: walletActions.setIsNewWalletUI,
    updateCountry: paymentActions.updateCountry,
  })(Payment)
);
