import React, { Fragment, useState, useEffect } from 'react';
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { displayGratuityFeature } from 'businessLogic/Payment/Gratuity';
import Hr from 'components/Shared/Hr/Hr';
import { ErrorAlert } from 'components/Shared/Icons/Icons';
import NewLabel from 'components/Shared/Icons/NewLabel';
import BaseInput from 'components/Shared/Inputs/BaseInput';
import Radio from 'components/Shared/Inputs/Radio';
import CpPopover from 'components/Shared/Popover/CpPopover';
import Separator from 'components/Shared/Separator/Separator';
import SegmentIO from 'reporting/SegmentIO';
import { PaymentAmountValidator } from 'shared/PaymentHelpers';
import { gratuityAmountChange } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { colors, breakpoints } from 'styles/cp';

export interface Props {
  paymentMethodType: string;
  gratuityEnabled: boolean;
  inputAmount: number;
  balanceAmount: number;
  intl?: any;
  gratuityAmountChange: Function;
  amount: number;
  currency: string;
  achConvenienceFeeAmount?: number;
}

const paymentsNotSupportedByTips = ['ap', 'pp'];

const Gratuity: React.FC<Props> = (props) => {
  const {
    paymentMethodType,
    inputAmount,
    balanceAmount,
    gratuityAmountChange,
    gratuityEnabled = false,
    amount = 0,
    currency = 'USD',
    achConvenienceFeeAmount = 0,
  } = props;
  const intl = useIntl();

  const [selected, setSelected] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [showGratuity, setShowGratuity] = useState(gratuityEnabled);
  const [isDisabled, setIsDisabled] = useState(false);
  const [gratuity, setGratuity] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const borderColor = inputError ? colors.error : colors.gray03;

  const disableRadioGroup = () => {
    setIsDisabled(true);
    setSelected(0);
    setGratuity('');
  };

  // gratuity business rules
  const validateGratuityAmount = (gratuityAmount: number) => {
    if (!PaymentAmountValidator.validateGratuity({ amount, gratuityAmount })) {
      setInputError(true);
      return false;
    }
    setInputError(false);
    return true;
  };

  const roundDownTwoDec = (num: number) => String(Math.floor(num * 100) / 100);

  useEffect(() => {
    const gratuityAmount = isNaN(parseFloat(gratuity)) ? 0 : parseFloat(gratuity);
    const validGratuity = validateGratuityAmount(gratuityAmount);
    if (!editMode || !validGratuity) {
      const gratuityValue = validGratuity ? gratuityAmount : 0;
      gratuityAmountChange(gratuityValue);
    }
  }, [gratuity]);

  useEffect(() => {
    if (gratuityEnabled) {
      setShowGratuity(true);
    } else {
      setShowGratuity(false);
      setSelected(0);
      setGratuity('');
    }
  }, [gratuityEnabled]);

  // when amount is not paid in full or apple pay is selected disable tips & reset to zero.
  useEffect(() => {
    const paymentSelectedNotSupported = paymentsNotSupportedByTips.includes(paymentMethodType);
    const isOverPayment = inputAmount !== balanceAmount + Number(achConvenienceFeeAmount);
    if (isOverPayment || paymentSelectedNotSupported) {
      disableRadioGroup();
    } else {
      setIsDisabled(false);
    }
  }, [inputAmount, balanceAmount, paymentMethodType]);

  // updated tip when tip radio button is updated
  useEffect(() => {
    switch (selected) {
      case 1:
        setGratuity(roundDownTwoDec(amount * 0.05)); //5% tip
        break;
      case 2:
        setGratuity(roundDownTwoDec(amount * 0.1)); //10% tip
        break;
      case 3:
        setGratuity(roundDownTwoDec(amount * 0.15)); //15% tip
        break;
      case 4:
        setGratuity(''); // custom tip
        setEditMode(true);
        break;
      default:
        setGratuity('');
        break;
    }
    SegmentIO.gratuityClicked(selected);
  }, [selected]);

  const onBlur = () => {
    if (inputError) {
      setGratuity('');
    } else if (!inputError && editMode) {
      const gratuityAmount = isNaN(parseFloat(gratuity)) ? 0 : parseFloat(gratuity);
      gratuityAmountChange(gratuityAmount);
      SegmentIO.gratuityOtherAmount(gratuityAmount);
    }
    setEditMode(false);
  };

  const onFocus = () => {
    setEditMode(true);
  };

  const onChange = (e: any) => {
    setGratuity(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
      return;
    } else if (e.key === 'Escape') {
      const eventBlur = e.currentTarget.blur.bind(e.target); //this will invoke onBlur func
      eventBlur();
      return;
    }
  };

  const onLabelClick = () => {
    setEditMode(true);
  };

  const getDisabledTooltipCopy = () => {
    const disabledPaymentType = paymentsNotSupportedByTips.find(
      (type) => type === paymentMethodType
    );
    switch (disabledPaymentType) {
      case 'ap':
        return 'TIPS_TOOLTIP_APPLE_PAY';
      case 'pp':
        return 'TIPS_TOOLTIP_PAYPAL';
      default:
        return 'TIPS_TOOLTIP_PAID_FULL';
    }
  };

  // hides gratuity section when user is not in ixp or relevant FF (including SCS flags)
  if (!showGratuity) {
    return null;
  }

  const gratuityAmount = isNaN(parseFloat(gratuity)) ? 0 : parseFloat(gratuity);
  const ToolTipCopy = getDisabledTooltipCopy();
  return (
    <Fragment>
      <div
        className="container"
        onMouseEnter={() => setIsTooltipOpen(true)}
        onTouchStart={() => setIsTooltipOpen(!isTooltipOpen)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        data-cy="gratuity-container"
      >
        <div className="header">
          <FormattedMessage id="TIPS_TITLE" defaultMessage="Tips" />
        </div>
        <div className="new-label">
          <div id="tooltip-target" />
          <NewLabel width={40} height={16} fontSize={12} />
        </div>
        <div className="user">
          <CpPopover
            className="cp-tooltip-wrapper"
            innerClassName="cp-tooltip"
            placement="top-start"
            isOpen={isDisabled && isTooltipOpen}
            target="tooltip-target"
          >
            <FormattedMessage
              id={ToolTipCopy}
              defaultMessage="In order to add a tip, the invoice must be paid in full"
            />
          </CpPopover>
          <div className="description" data-cy="gratuity-description">
            <FormattedMessage
              id="TIPS_SUMMARY_TEXT"
              defaultMessage="Show this business your appreciation with an optional tip."
            />
          </div>
          <div className="gratuity-item-group" data-cy="gratuity-item-group">
            <div
              className="labeledRadio-root"
              onClick={() => {
                setSelected(1);
              }}
            >
              <span className="labeledRadio-radio">
                <Radio checked={selected === 1} disabled={isDisabled} data-cy="gratuity-item-5p" />
              </span>
              <span className="labeledRadio-label">
                <FormattedMessage id="TIPS_RADIO_LABEL1" defaultMessage="5%" />
              </span>
            </div>
            <div
              className="labeledRadio-root"
              onClick={() => {
                setSelected(2);
              }}
            >
              <span className="labeledRadio-radio">
                <Radio checked={selected === 2} disabled={isDisabled} data-cy="gratuity-item-10p" />
              </span>
              <span className="labeledRadio-label">
                <FormattedMessage id="TIPS_RADIO_LABEL2" defaultMessage="10%" />
              </span>
            </div>
            <div
              className="labeledRadio-root"
              onClick={() => {
                setSelected(3);
              }}
            >
              <span className="labeledRadio-radio">
                <Radio checked={selected === 3} disabled={isDisabled} data-cy="gratuity-item-15p" />
              </span>
              <span className="labeledRadio-label">
                <FormattedMessage id="TIPS_RADIO_LABEL3" defaultMessage="15%" />
              </span>
            </div>
            <div
              className="labeledRadio-root other-radio"
              onClick={() => {
                setSelected(4);
              }}
            >
              <span className="labeledRadio-radio">
                <Radio
                  checked={selected === 4}
                  disabled={isDisabled}
                  data-cy="gratuity-item-other"
                  ariaLabel={intl.formatMessage({ id: 'CUSTOM_TIP', defaultMessage: 'Custom tip' })}
                />
              </span>
              {selected === 4 ? (
                <div className="gratuity-input">
                  {editMode ? (
                    <BaseInput
                      disabled={isDisabled}
                      type="text"
                      inputMode="decimal"
                      value={String(gratuity)}
                      onKeyDown={handleKeyPress}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      pattern="[0-9]*[.]?[0-9]{0,2}"
                      autoFocus
                      data-cy="gratuity-item-other-input"
                      ariaLabel={intl.formatMessage({
                        id: 'CUSTOM_TIP_AMOUNT',
                        defaultMessage: 'Custom tip amount',
                      })}
                    />
                  ) : (
                    <div onFocus={onLabelClick} onClick={onLabelClick} onTouchStart={onLabelClick}>
                      <label>
                        <FormattedNumber
                          value={gratuityAmount}
                          style="currency"
                          currency={currency}
                        />
                      </label>
                    </div>
                  )}
                  <Hr marginTop={0} marginBottom={0} borderColor={borderColor} />
                </div>
              ) : (
                <span className="labeledRadio-label">
                  <FormattedMessage id="TIPS_RADIO_LABEL4" defaultMessage="Other" />
                </span>
              )}
            </div>
            <div
              className="labeledRadio-root"
              onClick={() => {
                setSelected(5);
              }}
            >
              <span className="labeledRadio-radio">
                <Radio
                  checked={selected === 5}
                  disabled={isDisabled}
                  data-cy="gratuity-item-none"
                />
              </span>
              <span className="labeledRadio-label">
                <FormattedMessage id="TIPS_RADIO_LABEL5" defaultMessage="None" />
              </span>
            </div>
          </div>
        </div>
        {inputError && (
          <span className="error-message-wrapper" data-cy="gratuity-error-message">
            <ErrorAlert />
            &nbsp;
            <span className="error-message">
              <FormattedMessage id="TIPS_INVALID_AMOUNT" defaultMessage="Invalid Amount" />
            </span>
          </span>
        )}
      </div>
      <Separator height={16} />
      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          margin: 16px 0;
          @media screen and (max-width: ${breakpoints.sm}) {
            padding-bottom: 8px;
          }

          .header {
            display: inline-block;
            font-family: AvenirNextforINTUIT-Demi;
            font-size: 14px;
            color: ${colors.gray};

            @media screen and (max-width: ${breakpoints.sm}) {
              font-size: 14px;
              padding-right: 4px;
            }
          }

          .new-label {
            display: inline-block;
            margin-left: 10px;
          }

          .user {
            color: ${colors.gray02};
            @media screen and (max-width: ${breakpoints.sm}) {
              padding-bottom: 4px;
            }

            .description {
              font-size: 14px;
              line-height: 20px;
              color: ${colors.gray03};
            }

            .gratuity-item-group {
              margin-top: 15px;
              opacity: ${isDisabled ? '0.5' : '1'};
              pointer-events: ${isDisabled ? 'none' : 'all'};
              display: flex;
              justify-content: space-between;
              align-items: center;

              @media screen and (max-width: ${breakpoints.sm}) {
                flex-direction: column;
                align-items: start;
              }

              .labeledRadio-root {
                display: flex;
                justify-content: center;
                align-items: flex-end;

                @media screen and (max-width: ${breakpoints.sm}) {
                  align-items: center;
                  &:not(:last-child) {
                    margin-bottom: 8px;
                  }
                }

                .labeledRadio-radio {
                  flex: 0;
                  margin-bottom: 2px;
                }

                .labeledRadio-label {
                  margin-left: 8px;
                  font-size: 14px;
                  color: ${colors.gray02};
                  font-family: AvenirNextforINTUIT-Regular;
                }

                .gratuity-input {
                  width: 75px;
                  margin-left: 8px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export function mapStateToProps(store: any) {
  const {
    sale,
    payment: { inputAmount, paymentMethodType },
  } = store;

  return {
    paymentMethodType,
    inputAmount,
    balanceAmount: saleSelectors.balanceSelector(sale),
    amount: saleSelectors.amountSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    achConvenienceFeeAmount: saleSelectors.achOnlineConvenienceFeeAmountSelector(sale),
    gratuityEnabled: displayGratuityFeature(store),
  };
}

export { Gratuity };

export default connect(mapStateToProps, { gratuityAmountChange })(Gratuity);
