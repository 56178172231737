import { getCurrencySymbol } from 'businessLogic/server/scsToIcnAdapter';
import { getDayWithOrdinal, getMonthName, getOrdinal } from 'shared/dates';
import { capitalizeFirstLetter } from 'shared/utils';

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'components/subscription/utils';

const getWeekly = ({
  frequencyInterval,
  dayInWeek,
}: {
  frequencyInterval: number;
  dayInWeek: string;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} weeks` : 'Weekly';
  const suffix = `on ${capitalizeFirstLetter(dayInWeek)}`;
  return `${prefix} ${suffix}`;
};

const getYearly = ({ monthIndex, dayOfMonth }: { monthIndex: number; dayOfMonth: number }) => {
  if (dayOfMonth === 31) {
    return `Every year on the last day of ${getMonthName(monthIndex)}`;
  } else {
    return `Every year on ${getMonthName(monthIndex)} ${dayOfMonth}`;
  }
};

const getMonthly = ({
  frequencyInterval,
  dayInMonth,
}: {
  frequencyInterval: number;
  dayInMonth: number;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} months` : 'Monthly';
  const suffix = `on the ${getDayWithOrdinal(dayInMonth)} day`;
  return `${prefix} ${suffix}`;
};

const getRelativeMonthly = ({
  frequencyInterval,
  dayInWeek,
  weekIndex,
}: {
  frequencyInterval: number;
  dayInWeek: string;
  weekIndex: number;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} months` : 'Monthly';
  const suffix = `on the ${getOrdinal(weekIndex)} ${capitalizeFirstLetter(dayInWeek)}`;
  return `${prefix} ${suffix}`;
};

const getIntervalSentence = ({
  frequency,
  frequencyInterval,
  daysOfWeek,
  monthIndex,
  daysOfMonth,
  weekIndex,
}: {
  frequency?: string;
  frequencyInterval?: number;
  daysOfWeek?: string[];
  monthIndex?: number;
  daysOfMonth?: number[];
  weekIndex?: number;
}) => {
  switch (frequency) {
    case 'DAILY':
      return frequencyInterval
        ? frequencyInterval > 1
          ? `Every ${frequencyInterval} days`
          : 'Daily'
        : '';
    case 'WEEKLY':
      return frequencyInterval && daysOfWeek
        ? getWeekly({ frequencyInterval, dayInWeek: daysOfWeek[0] })
        : '';
    case 'MONTHLY':
      return frequencyInterval && daysOfMonth
        ? getMonthly({ frequencyInterval, dayInMonth: daysOfMonth[0] })
        : '';
    case 'RELATIVE_MONTHLY':
      return frequencyInterval && weekIndex && daysOfWeek
        ? getRelativeMonthly({ frequencyInterval, dayInWeek: daysOfWeek[0], weekIndex })
        : '';
    case 'YEARLY':
      return monthIndex && daysOfMonth ? getYearly({ monthIndex, dayOfMonth: daysOfMonth[0] }) : '';
    default:
      return '';
  }
};

const getFormattedMessageId = ({
  frequency,
  frequencyInterval,
  monthIndex,
  daysOfMonth,
}: {
  frequency?: string;
  frequencyInterval?: number;
  monthIndex?: number;
  daysOfMonth?: number[];
  weekIndex?: number;
}) => {
  switch (frequency) {
    case 'DAILY':
      return frequencyInterval ? (frequencyInterval > 1 ? 'DAILY_INTERVAL' : 'DAILY') : '';
    case 'WEEKLY':
      return frequencyInterval
        ? frequencyInterval > 1
          ? 'WEEKLY_INTERVAL'
          : 'EVERY_WEEK_INTERVAL'
        : '';
    case 'MONTHLY':
      return frequencyInterval
        ? frequencyInterval > 1
          ? 'MONTHLY_INTERVAL'
          : 'EVERY_MONTH_INTERVAL'
        : '';
    case 'RELATIVE_MONTHLY':
      return frequencyInterval
        ? frequencyInterval > 1
          ? 'RELATIVE_MONTHLY_INTERVAL'
          : 'RELATIVE_EVERY_MONTH_INTERVAL'
        : '';
    case 'YEARLY':
      return monthIndex && daysOfMonth
        ? daysOfMonth[0] === 31
          ? 'EVERY_YEAR_LAST_DAY_OF_MONTH'
          : 'EVERY_YEAR_INTERVAL'
        : '';
    default:
      return '';
  }
};

const getFormattedAmountWithoutTax = (
  amount: number,
  currency: string,
  taxAmount: number | null
) => {
  try {
    if (isNaN(amount) || amount < 0) {
      throw new Error('Invalid amount. Amount must be a non-negative number.');
    }

    if (typeof currency !== 'string' || currency.trim() === '') {
      throw new Error('Invalid currency. Currency must be a non-empty string.');
    }

    if (taxAmount !== null && (isNaN(taxAmount) || taxAmount < 0)) {
      throw new Error('Invalid tax amount. Tax amount must be a non-negative number or null.');
    }

    let formattedAmount = `${getCurrencySymbol(currency)}${(taxAmount !== null
      ? amount - taxAmount
      : amount
    ).toFixed(2)}`;

    if (formattedAmount.endsWith('.00')) {
      formattedAmount = formattedAmount.slice(0, -3);
    }

    return formattedAmount;
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'getFormattedAmountWithoutTax',
      error: {
        message: error,
      },
    });
    return '';
  }
};

export {
  getWeekly,
  getMonthly,
  getIntervalSentence,
  getFormattedMessageId,
  getFormattedAmountWithoutTax,
};
